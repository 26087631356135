import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  email = new UntypedFormControl('', [Validators.required,Validators.email]);

  isError = false;
  errorMessage = "";

  constructor(private authService: AuthService,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService) { }

  ngOnInit() {

  }

  forgotpassword() {
    this.ngxService.startLoader("loader-01");
    this.authService.forgetPassword(this.email.value)
    .subscribe(
        user => {
          setTimeout(() => {
            this.toastr.success("A link to your new password has been sent to your email", "success");
            this.router.navigateByUrl('/auth/login');
        }, 500);
        this.ngxService.stopLoader("loader-01");
        },
        error => {
          this.errorMessage = error;
          this.isError = true;
          setTimeout(() => {
              this.toastr.error(error, "Error");
          }, 500);
          this.ngxService.stopLoader("loader-01");
    });
  }

}
