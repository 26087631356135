import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, SimpleChanges } from '@angular/core'
import { RoundPipe } from '../pipes/round.pipe'

@Directive({
  selector: '[appToggableInput]',
  providers: [
    RoundPipe
  ]
})
export class ToggableInputDirective {
  private inputElement: HTMLInputElement
  private unit: string
  private value: string
  private valueWithoutUnit: string

  @Input() minValue: number
  @Input() maxValue: number
  @Input() roundPrecision: number
  @Input() disableInput: boolean = false
  @Input() displayInput: boolean = false
  @Output() valueChanged = new EventEmitter<number>()

  constructor(private el: ElementRef, private renderer: Renderer2, private roundPipe: RoundPipe) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disableInput && changes.disableInput.currentValue !== changes.disableInput.previousValue) {
      this.setDisabledStatus()
    }
    if (changes.displayInput && changes.displayInput.currentValue == true && changes.displayInput.currentValue !== changes.displayInput.previousValue) {
      this.turnIntoInput()
    }
  }

  @HostListener('click')
  onClick() {
    if (!this.inputElement) {
      this.turnIntoInput()
    }
  }

  private setDisabledStatus() {
    if (this.disableInput) this.renderer.setAttribute(this.inputElement, 'disabled', 'true')
    else this.renderer.removeAttribute(this.inputElement, 'disabled')
  }

  private turnIntoInput() {
    this.value = this.el.nativeElement.innerText
    this.valueWithoutUnit = this.value.split(' ')[0]
    this.unit = this.value.split(' ')[1]
    this.inputElement = this.renderer.createElement('input')
    this.renderer.addClass(this.inputElement, 'form-control')
    this.renderer.setAttribute(this.inputElement, 'type', 'number')
    this.inputElement.value = this.valueWithoutUnit
    this.renderer.setAttribute(this.inputElement, 'step', '0.01')
    if (this.disableInput) this.renderer.setAttribute(this.inputElement, 'disabled', 'true')
    else this.renderer.removeAttribute(this.inputElement, 'disabled')

    if ((this.minValue !== undefined && this.minValue !== null) && (this.maxValue !== undefined && this.minValue !== null)) {
      this.applyMinMaxValidator()
    }

    this.renderer.listen(this.inputElement, 'blur', () => this.onBlurOrEnter())
    this.renderer.listen(this.inputElement, 'keydown.enter', () => this.onBlurOrEnter())

    this.inputElement.style.width = '100px'

    this.renderer.removeChild(this.el.nativeElement.parentNode, this.el.nativeElement)
    this.renderer.appendChild(this.el.nativeElement.parentNode, this.inputElement)
    this.inputElement.focus()
  }

  private onBlurOrEnter() {
    const newValue = this.inputElement ? this.roundPrecision ? this.roundPipe.transform(parseFloat(this.inputElement.value), this.roundPrecision) : this.inputElement.value : '-'
    if (this.inputElement && this.inputElement.parentNode) {
      if (this.inputElement) this.valueChanged.emit(Number(newValue))
      const displayText = this.unit && this.unit.length ? `${newValue} ${this.unit}` : `${newValue}`;
      this.renderer.removeChild(this.inputElement.parentNode, this.inputElement);
      this.renderer.setProperty(this.el.nativeElement, 'textContent', displayText);
      this.renderer.appendChild(this.inputElement.parentNode, this.el.nativeElement);

      this.el.nativeElement.textContent = this.unit && this.unit.length ? `${newValue} ${this.unit}` : `${newValue}`
    }
    this.inputElement = undefined
  }

  private applyMinMaxValidator() {
    // No direct way to attach directive, but we can manually enforce min/max
    this.inputElement.min = this.minValue.toString();
    this.inputElement.max = this.maxValue.toString();

    // Listen to the input event to apply custom validation logic
    this.renderer.listen(this.inputElement, 'input', () => {
      const value = parseFloat(this.inputElement.value);
      if (value < this.minValue) {
        this.inputElement.value = this.minValue.toString();
      } else if (value > this.maxValue) {
        this.inputElement.value = this.roundPrecision ? this.roundPipe.transform(this.maxValue, this.roundPrecision) : this.maxValue.toString();
      }
    })
  }

}
