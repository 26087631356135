import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core'
import { Comment } from 'src/app/core/models/comment'
import { AuthService } from 'src/app/core/services/auth.service'

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrl: './comment.component.css',
})
export class CommentComponent {
  @Input() index: number
  @Input() comment: Comment
  @Output() onDelete = new EventEmitter<number>()
  @Output() onEdit = new EventEmitter<Comment>()

  enableEdit = false

  originalComment: Comment

  constructor(private authService: AuthService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.originalComment = new Comment({
      ...changes.comment.currentValue,
    })
  }

  editComment(commentText: string) {
    if (!this.canEdit) return
    const comment = new Comment({
      userName: this.authService.userName,
      commentDate: new Date().toString(),
      commentText: commentText,
      userId: this.authService.userId,
    })
    this.onEdit.emit(comment)
    this.enableEdit = false
  }

  deleteComment(commentIndex: number) {
    if (!this.canEdit) return
    this.onDelete.emit(commentIndex)
  }

  cancel() {
    this.comment = this.originalComment
    this.enableEdit = false
  }

  get canEdit(): boolean {
    return this.comment.userId === this.authService.userId
  }
}
