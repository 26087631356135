import { Component, OnInit } from '@angular/core'
import { SetTimeout } from '../../decorators/set-timeout'
import { BaseComponent } from '../base/base.component'

@Component({
  template: '',
})
export class BaseMapComponent extends BaseComponent implements OnInit {
  map: google.maps.Map

  constructor() {
    super()
  }

  ngOnInit() {}
}
