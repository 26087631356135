import { Directive, ElementRef, HostListener, Input, ComponentFactoryResolver, ApplicationRef, Injector, ComponentRef } from '@angular/core'
import { TooltipComponent } from '../views/tooltip/tooltip.component'

@Directive({
  selector: '[appTooltipDirective]'
})
export class TooltipDirective {
  @Input('appTooltipDirective') text: string = ''
  @Input() tooltipDelay: number = 0
  private tooltipComponentRef!: ComponentRef<TooltipComponent>

  constructor(
    private el: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) { }

  @HostListener('mouseenter') onMouseEnter() {
    setTimeout(() => {
      this.showTooltip()
    }, this.tooltipDelay)
  }

  private showTooltip() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TooltipComponent)
    this.tooltipComponentRef = componentFactory.create(this.injector)
    this.tooltipComponentRef.instance.text = this.text

    this.appRef.attachView(this.tooltipComponentRef.hostView)
    const domElem = (this.tooltipComponentRef.hostView as any).rootNodes[0] as HTMLElement
    document.body.appendChild(domElem)

    domElem.style.visibility = 'hidden'

    setTimeout(() => {
      const tooltipWidth = domElem.offsetWidth
      const tooltipHeight = domElem.offsetHeight
      const rect = this.el.nativeElement.getBoundingClientRect()
      const margin = 10

      let top = rect.top + (rect.height - tooltipHeight) / 2
      let left = rect.right + margin

      if (window.innerWidth < rect.right + tooltipWidth + margin) {
        if (rect.left > tooltipWidth + margin) {
          left = rect.left - tooltipWidth - margin
        } else {
          if (window.innerHeight < rect.bottom + tooltipHeight + margin) {
            top = rect.top - tooltipHeight - margin
          } else {
            top = rect.bottom + margin
          }
          left = rect.left
        }
      }

      this.tooltipComponentRef.instance.position = { left: `${left}px`, top: `${top}px` }

      domElem.style.visibility = 'visible'
    })
  }

  @HostListener('mouseleave') onMouseLeave() {
    setTimeout(() => {
      if (this.tooltipComponentRef) {
        this.appRef.detachView(this.tooltipComponentRef.hostView)
        this.tooltipComponentRef.destroy()
      }
    }, this.tooltipDelay);

  }
}
