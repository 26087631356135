import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
//import { MainLayoutComponent } from './main-layout/main-layout.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxSmartModalModule } from 'ngx-smart-modal';


const components = [
  //AuthLayoutComponent,
  //MainLayoutComponent,
];

@NgModule({
  imports: [
    //RouterModule,
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NgxSmartModalModule.forRoot()
  ],
  declarations: components,
  exports: components,
})
export class LayoutsModule { }
