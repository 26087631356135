import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'unit' })
export class UnitPipe implements PipeTransform {
  transform(value: any, unitType: string): string {
    if (!value) return '-'
    switch (unitType) {
      case 'cargo':
      case 'capacity':
      case 'consumption':
      case 'emission':
        return value + ' mt'; // 'mt' for metric tons
      case 'EUA':
        return value + ' EUA'; // 'EUA' for Emissions Unit Allowances
      case 'emission-factor':
        return value + ' kg/mt'; // 'kg/mt' for kilograms per metric ton
      case 'distance':
        return value + ' nm'; // 'nm' for nautical miles
      case 'speed':
        return value + 'knots'
      default:
        return value; // Return value as is if unittype is not recognized
    }
  }
}
