// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .sidebar{
    position: sticky;
    top:0;
    height: 100%;
  }
  
  /* Tooltip text */
  .tooltiptext {
    width: 180px;
    background-color: #27b374;
    opacity: 0.8;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    display: none;
   
    /* Position the tooltip text - see examples below! */
    position: absolute;
    top: 5px;
    left: 80%;
    z-index: 20;
  }

  .tooltiptext::after {
    content: "  ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #27b374 transparent transparent;
  }

  .tooltiptext-mirror {
    width: 180px;
    background-color: #27b374;
    opacity: 0.8;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
   
    /* Position the tooltip text - see examples below! */
    position: absolute;
    top: 5px;
    left: 80%;
    z-index: 20;
    transform: scaleX(-1);
  }

  `, "",{"version":3,"sources":["webpack://./src/app/layouts/main-layout/main-layout.component.css"],"names":[],"mappings":";EACE;IACE,gBAAgB;IAChB,KAAK;IACL,YAAY;EACd;;EAEA,iBAAiB;EACjB;IACE,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,cAAc;IACd,kBAAkB;IAClB,aAAa;;IAEb,oDAAoD;IACpD,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,WAAW;EACb;;EAEA;IACE,aAAa;IACb,kBAAkB;IAClB,QAAQ;IACR,WAAW,EAAE,+BAA+B;IAC5C,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,yDAAyD;EAC3D;;EAEA;IACE,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,cAAc;IACd,kBAAkB;;IAElB,oDAAoD;IACpD,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,WAAW;IAIX,qBAAqB;EACvB","sourcesContent":["\r\n  .sidebar{\r\n    position: sticky;\r\n    top:0;\r\n    height: 100%;\r\n  }\r\n  \r\n  /* Tooltip text */\r\n  .tooltiptext {\r\n    width: 180px;\r\n    background-color: #27b374;\r\n    opacity: 0.8;\r\n    color: #fff;\r\n    text-align: center;\r\n    padding: 5px 0;\r\n    border-radius: 6px;\r\n    display: none;\r\n   \r\n    /* Position the tooltip text - see examples below! */\r\n    position: absolute;\r\n    top: 5px;\r\n    left: 80%;\r\n    z-index: 20;\r\n  }\r\n\r\n  .tooltiptext::after {\r\n    content: \"  \";\r\n    position: absolute;\r\n    top: 50%;\r\n    right: 100%; /* To the left of the tooltip */\r\n    margin-top: -5px;\r\n    border-width: 5px;\r\n    border-style: solid;\r\n    border-color: transparent #27b374 transparent transparent;\r\n  }\r\n\r\n  .tooltiptext-mirror {\r\n    width: 180px;\r\n    background-color: #27b374;\r\n    opacity: 0.8;\r\n    color: #fff;\r\n    text-align: center;\r\n    padding: 5px 0;\r\n    border-radius: 6px;\r\n   \r\n    /* Position the tooltip text - see examples below! */\r\n    position: absolute;\r\n    top: 5px;\r\n    left: 80%;\r\n    z-index: 20;\r\n\r\n\r\n    -webkit-transform: scaleX(-1);\r\n    transform: scaleX(-1);\r\n  }\r\n\r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
