import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { BreadcrumbItem } from 'src/app/core/models/breadcrumb';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb-service.service';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  @Input() isDashboard = false
  breadcrumbs: BreadcrumbItem[]

  constructor(private breadcrumbService: BreadcrumbService,
    private dashboardService: DashboardService,
    private router: Router,
    public navigationService: NavigationService
  ) { }

  ngOnInit() {
    this.breadcrumbs = this.breadcrumbService.getBreadcrumbs();
    this.breadcrumbService.breadcrumbsChanged.subscribe((breadcrumbs: BreadcrumbItem[]) => {
      this.breadcrumbs = breadcrumbs;
    });
  }

  removeElementAndNavigate(el, url) {
    // remove elements to the right of the clicked one
    const labelToKeep = el;
    let indexToKeep: number | null = null;
    for (let i = 0; i < this.breadcrumbs.length; i++) {
      if (this.breadcrumbs[i].label === labelToKeep) {
        indexToKeep = i
        break
      }
    }

    if (indexToKeep !== null) {
      this.breadcrumbs = this.breadcrumbs.slice(0, indexToKeep + 1)
    }

    localStorage.setItem('breadcrumbs', JSON.stringify(this.breadcrumbs))

    // Navigate to the specified url
    if (url == '/dashboard') {
      this.dashboardService.refresh()
      this.router.navigateByUrl(url)
    }
    else {
      this.router.navigateByUrl(url)
    }

  }
}
