import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseProgressBarComponent implements OnInit {

  @Input() startValue: any
  @Input() endValue: any
  @Input() currentValue: any

  constructor() { }

  ngOnInit() {
  }

  // You can add common logic for progress calculation here
  get progress(): number {
    return ((this.currentValue - this.startValue) / (this.endValue - this.startValue)) * 100;
  }

}
