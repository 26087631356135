import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appMinMaxValidator]'
})
export class MinMaxValidatorDirective {
  @Input() min: number;
  @Input() max: number;

  constructor() { }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    const parsedValue = parseFloat(value);

    if (!isNaN(parsedValue)) {
      if (this.min !== undefined && parsedValue < this.min) {
        this.updateValue(this.min.toString());
      } else if (this.max !== undefined && parsedValue > this.max) {
        this.updateValue(this.max.toString());
      }
    }
  }

  private updateValue(value: string): void {
    (document.activeElement as HTMLInputElement).value = value;
    (document.activeElement as HTMLInputElement).dispatchEvent(new Event('input'));
  }
}
