import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError, first } from 'rxjs/operators'

import { AuthService } from '../services/auth.service'
import { ToastrService } from 'ngx-toastr'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        var user = this.authService.currentUserValue
        if (err.status === 401) {
          if (user) {
            setTimeout(() => {
              this.toastr.error(
                'You dont have access to view this resource, Please Login again',
                'Error'
              )
            }, 500)
            this.authService.logout()
          }
        }
        if (err.status === 409) {
          if (user) {
            setTimeout(() => {
              this.toastr.error(
                'You have been logged out because you signed in from another browser',
                'Error'
              )
            }, 500)
            this.authService.logout()
          }
        }

        const error = err.error?.message || err.statusText
        return throwError(error)
      })
    )
  }
}
