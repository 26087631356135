import { Component, OnInit } from '@angular/core'
import { UntypedFormControl, Validators } from '@angular/forms'
import { first } from 'rxjs/operators'
import { AuthService } from 'src/app/core/services/auth.service'
import { ActivatedRoute, Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { BehaviorSubject, Observable } from 'rxjs'
import { User } from '../../../core/models/user'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  email = new UntypedFormControl('', [Validators.required, Validators.email])
  password = new UntypedFormControl('', [Validators.required])

  isError = false
  errorMessage = ''
  roleValue = -1
  rememberMe = false

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService
  ) {}

  ngOnInit() {
    var rememberMeSubject = new BehaviorSubject<boolean>(
      JSON.parse(localStorage.getItem('rememberMe'))
    )
    if (rememberMeSubject.value != null) {
      this.rememberMe = rememberMeSubject.value
    }

    if (this.rememberMe) {
      var currentUserSubject = new BehaviorSubject<User>(
        JSON.parse(localStorage.getItem('currentUser'))
      )
      var user: User = currentUserSubject.value

      this.email.setValue(user.email)
      this.password.setValue(user.password)
    }
  }

  rememberMeChanged(event) {
    localStorage.setItem('rememberMe', JSON.stringify(event.target.checked))
  }

  showPassword = false
  showOrHidePassword() {
    this.showPassword = !this.showPassword
    var passwordField = document.getElementById('exampleInputPassword')
    if (this.showPassword) passwordField.setAttribute('type', 'text')
    else passwordField.setAttribute('type', 'password')
  }

  termOfUse = false
  termsOfUse(event) {
    this.termOfUse = event.target.checked
  }

  signin() {
    if (!this.termOfUse) {
      setTimeout(() => {
        this.toastr.error('Please accept terms of use', 'Error')
      }, 500)
      return
    }

    this.ngxService.startLoader('loader-01')
    this.authService
      .authenticate(this.email.value, this.password.value)
      .pipe(first())
      .subscribe(
        user => {
          this.roleValue = user.role
          this.authService.afterUserLogin(this.roleValue)
          this.ngxService.stopLoader('loader-01')
        },
        error => {
          this.errorMessage = error
          this.isError = true
          if (this.errorMessage.toString().includes('2FA Required')) {
            // this.router.navigateByUrl('/auth/two-step-verification')

          // Retrieve redirect value from query parameter
          const redirectValue = this.route.snapshot.queryParamMap.get('redirect');

          // Navigate to process-2fa with the retrieved redirect value as query parameter
          const redirectUrl = '/auth/two-step-verification';
          if (redirectValue) {
            const queryParams = { redirect: redirectValue };
            this.router.navigateByUrl(this.router.createUrlTree([redirectUrl], { queryParams }).toString());
          } else {
            this.router.navigateByUrl(redirectUrl); // Navigate without query parameter
          }

          } else {
            setTimeout(() => {
              this.toastr.error(error, 'Error')
            }, 500)
          }
          this.ngxService.stopLoader('loader-01')
        }
      )
  }
}
