import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'round'
})
export class RoundPipe implements PipeTransform {
  transform(value: number, precision: number = 2): string {
    if (value === null || value === undefined) return '-'
    return value.toFixed(precision)
  }
}
