import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  password = new FormControl('', [Validators.required]);
  confirmPassword = new FormControl('', [Validators.required]);

  isError = false;
  errorMessage = "";
  token = "";
  userId = "";

  constructor(private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['id']) {
        this.token = params['token'];
        this.userId = params['id']
      } 

  });
}

  validatePassword(): boolean {
    if (!this.password.valid) {
      setTimeout(() => {
        this.toastr.error("Password field Required", "Error");
      }, 500);

      return false;
    }
    if (!this.confirmPassword.valid) {
      setTimeout(() => {
        this.toastr.error("New Password field Required", "Error");
      }, 500);

      return false;
    }
    if (!this.confirmPassword.valid) {
      setTimeout(() => {
        this.toastr.error("Confirm password field Required", "Error");
      }, 500);

      return false;
    }

    if (this.password.value != this.confirmPassword.value) {
      setTimeout(() => {
        this.toastr.error("Confirm password is different from the password", "Error");
      }, 500);

      return false;
    }
    return true;
  }
  resetPassword() {
    if (this.validatePassword()) {
      this.ngxService.startLoader("loader-01");
      this.authService.resetPassword(this.token, this.userId, this.password.value)
        .subscribe(
          user => {
            setTimeout(() => {
              this.toastr.success("Password Updated Successfully", "Success");
            }, 500);
            this.ngxService.stopLoader("loader-01");
            this.router.navigateByUrl("/auth/login");
          },
          error => {

            setTimeout(() => {

              this.toastr.error(error);
            }, 500);
            this.ngxService.stopLoader("loader-01");
          });
    }

  }

  showPasswords: boolean[] = [false, false];
  
  showOrHidePassword(index: number): void {
    this.showPasswords[index] = !this.showPasswords[index];
  }

}
