export class Comment {
  userId: string
  userName: string
  commentDate: string
  commentText: string

  constructor(params?: Comment) {
    this.userId = params?.userId
    this.userName = params?.userName
    this.commentDate = params?.commentDate
    this.commentText = params?.commentText
  }
}
