import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PortLegFossilFuelGrades } from 'src/app/core/models/fuel';
import { Utils } from '../../utils';

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RangeSliderComponent implements AfterViewInit {
  @Input() value: number = 0
  @Input() min: number = 0 // Default minimum value
  @Input() max: number = 100 // Default maximum value
  @Input() step: number = 1
  @Input() disable = true
  @Output() updatedValue = new EventEmitter<number>()
  @Input() fossilFuelGrades: PortLegFossilFuelGrades
  @Input() rightColor = '#206A5D'

  percentage: number = 50

  hoveredIndex = -1

  @ViewChild('slider', { static: false }) slider: ElementRef<HTMLInputElement>

  constructor(private changeDetector: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.updateSliderStyle()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value || changes.fossilFuelGrades) {
      setTimeout(() => {
        this.updateSliderStyle()
      })
    }
  }

  updateValue(): void {
    this.updateSliderStyle()
    this.updatedValue.emit(Utils.round(this.value, 3))
  }

  addHoverIndex(index: number) {
    this.changeDetector.detach()
    this.hoveredIndex = index
    this.changeDetector.reattach()
  }

  removeHoverIndex() {
    this.changeDetector.detach()
    this.hoveredIndex = -1
    this.changeDetector.reattach()
  }

  updateSliderStyle(): void {
    this.percentage = this.value / this.max * 100
    const percentageNumber = this.percentage / 100
    let leftColors = []

    leftColors = this.fossilFuelGrades.grades.map((grade, index) => {
      const startPercentage = index === 0 ? 0 : Number(this.fossilFuelGrades.grades[index - 1].percentage) * percentageNumber * 100
      const endPercentage = (index === this.fossilFuelGrades.grades.length - 1 ? this.percentage : Number(grade.percentage) * percentageNumber * 100)
      return `${grade.color} ${startPercentage}%, ${grade.color} ${endPercentage}%`
    })

    let gradient = `linear-gradient(to right, ${[...leftColors, `${this.rightColor} ${this.percentage}%`].join(', ')})`

    this.slider.nativeElement.style.setProperty('--slider-gradient', gradient)
    this.changeDetector.detectChanges()
  }

}
