// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-view {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.loading-view-dark {
  background-color:rgba(189, 189, 189, 0.397);
}

.loading-view-light {
  background-color: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/views/loading-view/loading-view.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,aAAa;AACf;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".loading-view {\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  right: 0;\r\n  bottom: 0;\r\n  z-index: 1000;\r\n}\r\n\r\n.loading-view-dark {\r\n  background-color:rgba(189, 189, 189, 0.397);\r\n}\r\n\r\n.loading-view-light {\r\n  background-color: inherit;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
