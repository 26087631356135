import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';

import { NgxUiLoaderModule } from "ngx-ui-loader";
import { TwoStepVerificationComponent } from './two-step-verification/two-step-verification.component';
import { Process2faComponent } from './process-2fa/process-2fa.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

@NgModule({
  declarations: [
    LoginComponent,
    ForgetPasswordComponent,
    TwoStepVerificationComponent,
    Process2faComponent,
    VerifyEmailComponent,
    ResetPasswordComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxUiLoaderModule,
  ]
})
export class AuthModule { }
