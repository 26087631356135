import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.css']
})
export class UploadButtonComponent {

  @Output() fileSelected = new EventEmitter<File>()
  @Output() fileRemoved = new EventEmitter<unknown>()
  @Input() filename: string | null = null
  @Input() buttonLabel = 'Upload'

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.filename.currentValue === null && changes.filename.currentValue !== changes.filename.previousValue) {
      this.buttonLabel = 'Upload'
      this.resetFileInput()
    }
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0]
    if (file) {
      this.filename = file.name
      this.fileSelected.emit(file)
    }
  }

  removeFile(event: MouseEvent) {
    event.preventDefault()
    event.stopPropagation()
    this.filename = null
    this.resetFileInput()
  }

  private resetFileInput() {
    const input = document.getElementById('file') as HTMLInputElement
    input.value = ''
    this.fileRemoved.emit()
  }
}
