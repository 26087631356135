import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'toggle-alert-button',
    templateUrl: './toggle-alert-button.component.html',
    styleUrls: ['./toggle-alert-button.component.scss']
})
export class ToggleAlertButtonComponent {
    @Output() changed = new EventEmitter<boolean>();
    @Input() disabled = null;
    @Input() checked = false;
}
