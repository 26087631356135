/**
 * Set the current function as a callback to setTimeout
 * @param delay delay in milliseconds
 * @returns
 */
export function SetTimeout(delay: number = 0): MethodDecorator {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value

    descriptor.value = function (...args: any[]) {
      setTimeout(() => {
        originalMethod.apply(this, args)
      }, delay)
    }

    return descriptor
  }
}
