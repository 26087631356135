import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SetTimeout } from '../decorators/set-timeout';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public activatedPage: BehaviorSubject<string> = new BehaviorSubject("Dashboard")
  public activatedPage$: Observable<string> = this.activatedPage.asObservable()
  public activatedPageSubtitle: string = null
  isDarkMode = new BehaviorSubject<any>(false);
  //isDarkMode = new Subject<any>();
  isDashboard = new Subject<any>();
  public shortcutTrigger$: EventEmitter<Number>; // 1 for search vessel, 2 for search port, 3 for search routes
  public portTrigger$: EventEmitter<boolean>;
  public startLatTrigger$: EventEmitter<string>;
  public startLngTrigger$: EventEmitter<string>;
  public stopLatTrigger$: EventEmitter<string>;
  public stopLngTrigger$: EventEmitter<string>;
  constructor() {
    this.shortcutTrigger$ = new EventEmitter();
    this.portTrigger$ = new EventEmitter();
    this.startLatTrigger$ = new EventEmitter();
    this.startLngTrigger$ = new EventEmitter();
    this.stopLatTrigger$ = new EventEmitter();
    this.stopLngTrigger$ = new EventEmitter();
  }

  public triggerShortcut(eventId: Number): void {
    this.shortcutTrigger$.emit(eventId);
  }

  public triggerStartLat(value: string): void {
    this.startLatTrigger$.emit(value);
  }

  public triggerStartLng(value: string): void {
    this.startLngTrigger$.emit(value);
  }

  public triggerStopLat(value: string): void {
    this.stopLatTrigger$.emit(value);
  }

  public triggerStopLng(value: string): void {
    this.stopLngTrigger$.emit(value);
  }

  public triggerPortLoaded(status: boolean): void {
    this.portTrigger$.emit(status);
  }

  @SetTimeout()
  public scrollToBottom(): void {
    document.getElementById('bottom')?.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }

  @SetTimeout()
  public scrollToTop(): void {
    document.getElementById('wrapper')?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

}
