import { Component, input, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-view',
  templateUrl: './loading-view.component.html',
  styleUrls: ['./loading-view.component.css']
})
export class LoadingViewComponent {

  /** Show dark background of loader spinner */
  background = input<boolean>(true)

}
