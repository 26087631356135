import { Component, Input, OnInit } from '@angular/core';

/** Component representing a KPI (Key Performance Indicator) */
@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.css']
})
export class KPIComponent implements OnInit {

  /** KPI title/name @type {string} */
  @Input() title: string
  /** KPI value @type {number | string} */
  @Input() value: number | string = 0
  /** KPI value type @type {KPIType} (e.g., 'EUAs', 'currency' etc.) */
  @Input() valueType: KPIType

  constructor() { }

  ngOnInit() {
  }

  get isCurrency(): boolean {
    return this.valueType === 'currency'
  }

  get isString(): boolean {
    return this.valueType === 'string'
  }

}

export type KPIType = 'EUA' | 'currency' | 'mt' | 'time' | 'string'
