import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-star',
  templateUrl: './star.component.html',
  styleUrls: ['./star.component.css']
})
export class StarComponent {

  @Input() stars: number = 0;
  @Input() flavor: string = "primary";
 
  constructor() { }
}
