// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav > ol.breadcrumb-nav {
  background-color: none !important;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
`, "",{"version":3,"sources":["webpack://./src/app/views/breadcrumb/breadcrumb.component.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":["nav > ol.breadcrumb-nav {\r\n  background-color: none !important;\r\n  list-style: none;\r\n  display: flex;\r\n  align-items: center;\r\n  flex-wrap: wrap;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
