export class User {
  id?: string
  firstName?: string
  lastName?: string
  postFixPIC?: string
  email?: string
  role?: any
  roleString?: string
  customerId?: string
  customerName?: string
  password?: string
  access_token?: string
  refresh_token?: string
  expires_in?: number
  vesselIds?: string[]
  vesselId?: string
  createdAt?: Date
  portIds?: Array<{}>
  additionalEmails?: string
  secretKey?: string
  isValidated?: boolean
  twoFaType?: string
  validatedUsers?: string[]
  twoFactorEnabled?: boolean
}

export class Surveyor {
  deliveryId?: string
  surveyorId?: string
}

export const UserRoles = {
  '0': 'VESSEL',
  '1': 'C4',
  '2': 'SUPER_USER',
  '3': 'CUSTOMER1',
  '4': 'CUSTOMER2',
  '5': 'OPERATOR1',
  '6': 'SURVEYOR',
  '7': 'ROUTING_OPERATOR',
  '8': 'EUA_INVENTORY_MANAGER',
  '9': 'SUPER_OPERATOR',
  VESSEL: '0',
  C4: '1',
  SUPER_USER: '2',
  CUSTOMER1: '3',
  CUSTOMER2: '4',
  OPERATOR1: '5',
  SURVEYOR: '6',
  ROUTING_OPERATOR: '7',
  EUA_INVENTORY_MANAGER: '8',
  SUPER_OPERATOR: '9',
}
