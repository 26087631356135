import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { BehaviorSubject, Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { User, UserRoles } from '../models/user'
import { map } from 'rxjs/operators'
import { PermissionSet } from '../models/permission-set'
import { Resources } from '../enums'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User | undefined> =
    new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')))
  public currentUser: Observable<User> = this.currentUserSubject.asObservable()

  public isInImpersonatorModeSubject = new BehaviorSubject<boolean>(
    !!localStorage.getItem('impersonatorUser')
  )
  public isInImpersonatorMode$ = this.isInImpersonatorModeSubject.asObservable()

  private tempUserSubject: BehaviorSubject<User>
  public tempUser: Observable<User>

  private _url: string = `${environment.apiUrl}/api/Auth/`

  rememberMe = false

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    //private userService: UserService,
    private router: Router
  ) {
    var rememberMeSubject = new BehaviorSubject<boolean>(
      JSON.parse(localStorage.getItem('rememberMe'))
    )
    if (rememberMeSubject.value != null) {
      this.rememberMe = rememberMeSubject.value
    }

    if (this.rememberMe) {
      this.currentUserSubject = new BehaviorSubject<User>(
        JSON.parse(localStorage.getItem('currentUser'))
      )
      this.currentUser = this.currentUserSubject.asObservable()
    } else {
      this.currentUserSubject = new BehaviorSubject<User>(
        JSON.parse(sessionStorage.getItem('currentUser'))
      )
      this.currentUser = this.currentUserSubject.asObservable()
    }
    const tempUserFromStorage = JSON.parse(localStorage.getItem('tempUser'))
    this.tempUserSubject = new BehaviorSubject<User>(tempUserFromStorage)
    this.tempUser = this.tempUserSubject.asObservable()
  }

  authenticate(email: string, password: string) {
    return this.http
      .post(this._url + 'SignIn', { email: email, password: password })
      .pipe(
        map(responce => {
          var user: User = new User()
          user = responce['data']

          if (user.isValidated) {
            var isVerified = JSON.parse(localStorage.getItem('isValidated'))
            var validatedUsers = JSON.parse(
              localStorage.getItem('validatedUsers')
            )
            if (
              isVerified &&
              validatedUsers &&
              validatedUsers.includes(user.id)
            ) {
              localStorage.setItem('currentUser', JSON.stringify(user))
              this.currentUserSubject.next(user)
              return user
            } else {
              localStorage.setItem('tempUser', JSON.stringify(user))
              this.tempUserSubject.next(user)
              throw new Error('2FA Required')
            }
          }
          if (user.twoFactorEnabled && !user.isValidated) {
            localStorage.setItem('tempUser', JSON.stringify(user))
            this.tempUserSubject.next(user)

            // Retrieve redirect value from query parameter
          const redirectValue = this.route.snapshot.queryParamMap.get('redirect');

          // Navigate to process-2fa with the retrieved redirect value as query parameter
          const redirectUrl = '/auth/process-2fa';
          if (redirectValue) {
            const queryParams = { redirect: redirectValue };
            this.router.navigateByUrl(this.router.createUrlTree([redirectUrl], { queryParams }).toString());
          } else {
            this.router.navigateByUrl(redirectUrl); // Navigate without query parameter
          }

            // this.router.navigateByUrl('/auth/process-2fa')
            return
          }

          if (this.rememberMe) {
            localStorage.setItem('currentUser', JSON.stringify(user))
            this.currentUserSubject.next(user)
          } else {
            sessionStorage.setItem('currentUser', JSON.stringify(user))
            this.currentUserSubject.next(user)
          }

          return user
        })
      )
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value
  }

  public setCurrentUser(): void {
    if(!this.currentUserSubject.value)
    this.currentUserSubject.next(JSON.parse(localStorage.getItem('currentUser')));
  }

  get isInImpersonatorMode(): boolean {
    return this.isInImpersonatorModeSubject.getValue()
  }

  logout() {
    // remove user from local storage to log user out

    if (this.rememberMe) {
      localStorage.removeItem('currentUser')
      this.currentUserSubject.next(null)
    } else {
      sessionStorage.removeItem('currentUser')
      this.currentUserSubject.next(null)
    }
    if (this.isInImpersonatorMode$) this.exitImpersonatorMode()
    //localStorage.removeItem('isValidated');
    this.router.navigateByUrl('/auth/login')
  }

  changePassword(
    password: string,
    newPassword: string,
    confirmPassword: string
  ) {
    return this.http.post(this._url + 'ChangePassword', {
      currentPassword: password,
      newPassword: newPassword,
      '': confirmPassword,
    })
  }

  setPassword(userId: string, newPassword: string) {
    return this.http.post(this._url + 'SetPassword', {
      userId: userId,
      Password: newPassword,
    })
  }

  forgetPassword(email: string) {
    return this.http.post(this._url + 'ForgotPassword', { email: email })
  }

  resetPassword(token: string, userId: string, newPassword: string) {
    return this.http.post(this._url + 'ResetPassword', {
      token: token,
      userId: userId,
      Password: newPassword,
    })
  }

  refreshToken() {
    return this.http
      .post(this._url + 'RefreshToken', {
        refreshToken: this.currentUserValue.refresh_token,
      })
      .pipe(
        map(responce => {
          var user: User = new User()
          user = responce['data']

          if (this.rememberMe) {
            localStorage.setItem('currentUser', JSON.stringify(user))
            this.currentUserSubject.next(user)
          } else {
            sessionStorage.setItem('currentUser', JSON.stringify(user))
            this.currentUserSubject.next(user)
          }
          return user
        })
      )
  }
  getQRCodeImage(secretKey: string, userName: string) {
    return this.http.get(
      this._url +
        'GenerateQRCode' +
        `?secretKey=${secretKey}&username=${userName}`
    )
  }
  verifyQRCode(secretKey: string, qrCode: string, userId: string) {
    return this.http.get(
      this._url +
        'ValidateQRCode' +
        `?secretKey=${secretKey}&userProvidedCode=${qrCode}&userId=${userId}`
    )
  }
  disable2Fa(userId: string) {
    return this.http.get(this._url + 'Disable2FA' + `?userId=${userId}`)
  }
  send2FaEmail(userId: string) {
    return this.http.post(this._url + 'Send2FAEmail?id=' + userId, {})
  }
  verifyEmailCode(qrCode: string, userId: string) {
    return this.http.get(
      this._url +
        'ValidateEmailCode' +
        `?userProvidedCode=${qrCode}&userId=${userId}`
    )
  }

  get userName(): string {
    const firstName = this.currentUserValue.firstName
    const lastName = this.currentUserValue.lastName
    const userName = `${firstName} ${lastName}`
    return userName
  }

  get userId(): string {
    return this.currentUserValue.id
  }

  get userRole(): string {
    return this.currentUserValue.role
  }

  get isAuthenticated(): boolean {
    return !!localStorage.getItem('currentUser')
  }

  getImpersonatedUserDetails(userId: string) {
    return this.http.post(this._url + 'Impersonate', { userId: userId })
  }

  getSavedImpersonatedUserDetails() {
    if (this.isInImpersonatorMode) {
      return JSON.parse(localStorage.getItem('currentUser'))
    } else null
  }

  enterImpersonatorMode(selectedUser: Partial<User>) {
    const currentUser = localStorage.getItem('currentUser')
    const impersonatedUser = JSON.stringify(selectedUser)
    localStorage.setItem('impersonatorUser', currentUser)
    localStorage.setItem('currentUser', impersonatedUser)
    this.isInImpersonatorModeSubject.next(true)
    this.currentUserSubject.next(
      JSON.parse(localStorage.getItem('currentUser'))
    )
    this.router.navigate(['dashboard'])
  }

  exitImpersonatorMode() {
    const impersonatorUser = localStorage.getItem('impersonatorUser')
    localStorage.setItem('currentUser', impersonatorUser)
    localStorage.removeItem('impersonatorUser')
    this.isInImpersonatorModeSubject.next(false)
    this.currentUserSubject.next(
      JSON.parse(localStorage.getItem('currentUser'))
    )
  }

  afterUserLogin(role: number) {
    if (role == 1 || role == 2 || role == 5 || role == 6 || role == 7 || role == 9) {
      let redirectUrl = this.route.snapshot.queryParamMap.get('redirect')
      if (redirectUrl != null) {
        var urlRegex =
          /^(?:https?:\/\/)?(?:www\.)?(?:localhost|\b(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,}\b)(?::\d{1,5})?(?:\/[\w.-]*)*(?:\?.*)?$/i

        if (urlRegex.test(redirectUrl)) {
          var redirect = redirectUrl
            .split('://')[1]
            .split('/')
            .slice(1)
            .join('/')
            if(redirect !== null && redirect !== undefined && redirect.trim() !== '') {
              this.router.navigateByUrl('/' + redirect);
              return;
          }
        }
      }
      if (this.router.url.includes('/auth/login')){
      if (role == 6 )
        this.router.navigateByUrl("/deliverables");
        else
        this.router.navigateByUrl("/dashboard");
      }
    }
    else if (role == 8) {
      this.router.navigateByUrl('/eua-inventory')
    }
    else {
      this.router.navigateByUrl('/deliverables')
    }
  }
}
