import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BreadcrumbItem } from '../models/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private breadcrumbs: BreadcrumbItem[] = [];
  breadcrumbsChanged: BehaviorSubject<BreadcrumbItem[]> = new BehaviorSubject<BreadcrumbItem[]>([]);
  getBreadcrumbs(): BreadcrumbItem[] {
    const data = JSON.parse(localStorage.getItem("breadcrumbs"));
    if(data != null)
      this.breadcrumbs = data;
      this.breadcrumbsChanged.next(this.breadcrumbs);
      return this.breadcrumbs;
  }
  empty() {
    localStorage.removeItem("breadcrumbs");
    return this.breadcrumbs = [];
  }
  addBreadcrumb(item: BreadcrumbItem) {
    if(!this.breadcrumbs.find(el => el.label === item.label))
      this.breadcrumbs.push(item);
      localStorage.setItem("breadcrumbs", JSON.stringify(this.breadcrumbs));
    this.breadcrumbsChanged.next(this.breadcrumbs);
  }
  removeLastBreadcrumb() {
    this.breadcrumbs.pop();
    localStorage.setItem("breadcrumbs", JSON.stringify(this.breadcrumbs));
    this.breadcrumbsChanged.next(this.breadcrumbs);
  }
}