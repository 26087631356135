import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'durationFormat',
})
export class DurationFormatPipe implements PipeTransform {
  /**
   * Takes milliseconds or duration in days (string) and returns formatted duration
   * @param duration {milliseconds (number) or durationInDays (string)}
   * @param removeMinutesAndSeconds {boolean} If true, removes minutes and seconds from the result
   * @returns string (formatted duration)
   */
  transform(
    duration: number | string,
    removeMinutesAndSeconds: boolean = false
  ): string {
    if (typeof duration === 'number') {
      return this.transformMilliseconds(duration, removeMinutesAndSeconds)
    } else if (typeof duration === 'string') {
      return this.transformDurationString(duration, removeMinutesAndSeconds)
    } else {
      return duration
    }
  }

  private transformMilliseconds(
    milliseconds: number,
    removeMinutesAndSeconds: boolean
  ): string {
    const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24))
    const hours = Math.floor(
      (milliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )

    let result = ''
    if (days > 0) {
      result += days + ' day'
      if (days > 1) result += 's'
    }
    if (hours > 0) {
      if (result) result += ' '
      result += hours + ' hour'
      if (hours > 1) result += 's'
    }

    if (!removeMinutesAndSeconds) {
      const minutes = Math.floor(
        (milliseconds % (1000 * 60 * 60)) / (1000 * 60)
      )
      const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000)

      if (minutes > 0) {
        if (result) result += ' '
        result += minutes + ' minute'
        if (minutes > 1) result += 's'
      }
      if (seconds > 0) {
        if (result) result += ' '
        result += seconds + ' second'
        if (seconds > 1) result += 's'
      }
    }

    return result || '0 hours' // Fallback to avoid empty string
  }

  private transformDurationString(
    durationInDays: string,
    removeMinutesAndSeconds: boolean
  ): string {
    const matches = durationInDays
      .trim()
      .match(/(?:(\d+)d)?(?:(\d+)h)?(?:(\d+)m)?(?:(\d+)s)?/)

    if (!matches) {
      return durationInDays
    }

    // Extract the days, hours, minutes, and seconds
    const days = matches[1] ? matches[1] + 'd' : ''
    const hours = matches[2] ? matches[2] + 'h' : ''
    const minutes = matches[3] ? matches[3] + 'm' : ''
    const seconds = matches[4] ? matches[4] + 's' : ''

    let result = `${days}${hours}`

    if (!removeMinutesAndSeconds) {
      result += `${minutes}${seconds}`
    }

    return result.trim() || '0 hours' // Fallback to avoid empty string
  }
}
