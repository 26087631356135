import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {
  transform(value: any[], property: string, reverse: boolean = false): any[] {
    if (!value || !property) {
      return value
    }

    value.sort((a, b) => {
      if (a[property] < b[property]) {
        return reverse ? 1 : -1
      } else if (a[property] > b[property]) {
        return reverse ? -1 : 1
      } else {
        return 0
      }
    })

    return value
  }
}
