import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core'
import { BehaviorSubject, map } from 'rxjs'
import { Resources } from 'src/app/core/enums'
import { AuthService } from 'src/app/core/services/auth.service'
import { NavigationService } from 'src/app/shared/services/navigation.service'
import { NgxSmartModalService } from 'ngx-smart-modal'
import { DashboardService } from 'src/app/core/services/dashboard.service'
import { environment } from '../../../environments/environment'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { HoverMenuItem } from 'src/app/shared/directives/hover-menu.directive'

declare var $: any

export interface SidebarSubMenuItem extends HoverMenuItem {
  visible: boolean
}

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css'],
})
export class MainLayoutComponent implements OnInit {
  roleValue = -1

  isSidebarOpen = false

  showFilters = false

  darkMode = false
  isDashboard = false
  voyagesSubmenu: SidebarSubMenuItem[] = []
  reportsSubmenu: SidebarSubMenuItem[] = []
  administrationSubmenu: SidebarSubMenuItem[] = []

  constructor(
    private authService: AuthService,
    private dashboardService: DashboardService,
    private ngxService: NgxUiLoaderService,
    public ngxSmartModalService: NgxSmartModalService,
    private changeDetector: ChangeDetectorRef,
    public navigationService: NavigationService
  ) {
    this.authService.currentUser
      .pipe(map(user => Number(user.role)))
      .subscribe(role => {
        this.roleValue = role
        this.authService.afterUserLogin(this.roleValue)
        this.initSubMenuItems()
      })
    this.navigationService.isDashboard.subscribe(e => {
      this.isDashboard = e
    })
  }

  ngOnInit() {
    this.roleValue = this.authService.currentUserValue.role
    this.initSubMenuItems()
    this.loadJQuery()
    this.toggleMode()
    const darkModeSubject = new BehaviorSubject<boolean>(
      JSON.parse(localStorage.getItem('darkMode'))
    )
    this.darkMode = darkModeSubject.value
    this.addPortObserver()
  }

  refresh() {
    this.dashboardService.refresh()
  }

  initSubMenuItems() {
    this.voyagesSubmenu = [
      {
        name: 'Voyage Overview',
        url: '/voyage-overview',
        visible: [1, 2, 3, 5, 7, 9].includes(this.roleValue),
      },
      {
        name: 'Fuel Strategy',
        url: '/fuel-strategy',
        visible: [1, 2, 3, 5, 7, 9].includes(this.roleValue),
      },
    ]
    this.reportsSubmenu = [
      {
        name: 'General Reports',
        url: '/reports/reporting',
        visible: [1, 2, 3, 5, 7, 9].includes(this.roleValue),
      },
      {
        name: 'Weather Reports',
        url: '/weather-report',
        visible: this.roleValue === 2,
      },
    ]
    this.administrationSubmenu = [
      {
        name: 'Vessels',
        url: '/vessels',
        visible: [1, 2, 7].includes(this.roleValue),
      },
      {
        name: 'Operation Data',
        url: '/administration',
        visible: [1, 2, 7].includes(this.roleValue),
      },
      {
        name: 'Customers',
        url: '/customers',
        visible: [1, 2, 3, 7].includes(this.roleValue),
      },
      { name: 'Users', url: '/users', visible: this.roleValue === 2 },
      {
        name: 'Areas',
        url: '/administration/areas',
        visible: this.roleValue === 3,
      },
    ]
  }

  toggleVesselList() {
    this.dashboardService.toggleVesselsList()
  }

  toggleFilters() {
    this.showFilters = !this.showFilters
    this.dashboardService.toggleFilters()
  }

  portLoaded = false
  addPortObserver() {
    this.navigationService.portTrigger$.subscribe(status => {
      this.portLoaded = status
    })
  }

  toggleMode(isDarkMode = false) {
    localStorage.setItem('darkMode', JSON.stringify(isDarkMode))
    this.darkMode = isDarkMode
    this.navigationService.isDarkMode.next(isDarkMode)
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen
  }

  logout() {
    this.authService.logout()
  }

  openChat() {
    window['fcWidget'].open()
  }

  emitShortcut(eventId: Number) {
    if (eventId == 5) {
      this.ngxService.startLoader('loader-01')
      this.dashboardService.getRouteLinkToken().subscribe(response => {
        let completeLink = environment.routelink + '?auth=' + response.data
        window.open(completeLink, '_blank')
      })
      this.ngxService.stopLoader('loader-01')
    }
    if (eventId == 2) {
      this.ngxSmartModalService.getModal('portSearchModal').open()
    }
    if (eventId === 3) {
      this.ngxSmartModalService.getModal('bunkerIndication').open()
    } else {
      this.navigationService.triggerShortcut(eventId)
    }
  }

  loadJQuery() {
    $('#sidebarToggle, #sidebarToggleTop').on('click', function (e) {
      $('body').toggleClass('sidebar-toggled')
      $('.sidebar').toggleClass('toggled')
      if ($('.sidebar').hasClass('toggled')) {
        $('.sidebar .collapse').collapse('hide')
      }
    })

    // Close any open menu accordions when window is resized below 768px
    $(window).resize(function () {
      if ($(window).width() < 768) {
        $('.sidebar .collapse').collapse('hide')
      }

      // Toggle the side navigation when window is resized below 480px
      if ($(window).width() < 480 && !$('.sidebar').hasClass('toggled')) {
        $('body').addClass('sidebar-toggled')
        $('.sidebar').addClass('toggled')
        $('.sidebar .collapse').collapse('hide')
      }
    })

    // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
    $('body.fixed-nav .sidebar').on(
      'mousewheel DOMMouseScroll wheel',
      function (e) {
        if ($(window).width() > 768) {
          var e0 = e.originalEvent,
            delta = e0.wheelDelta || -e0.detail
          this.scrollTop += (delta < 0 ? 1 : -1) * 30
          e.preventDefault()
        }
      }
    )

    // Scroll to top button appear
    $(document).on('scroll', function () {
      var scrollDistance = $(this).scrollTop()
      if (scrollDistance > 100) {
        $('.scroll-to-top').fadeIn()
      } else {
        $('.scroll-to-top').fadeOut()
      }
    })

    // Smooth scrolling using jQuery easing
    $(document).on('click', 'a.scroll-to-top', function (e) {
      var $anchor = $(this)
      $('html, body')
        .stop()
        .animate(
          {
            scrollTop: $($anchor.attr('href')).offset().top,
          },
          1000,
          'easeInOutExpo'
        )
      e.preventDefault()
    })
  }
}
