import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { LoginComponent } from './login/login.component';
import { TwoStepVerificationComponent } from './two-step-verification/two-step-verification.component';
import { Process2faComponent } from './process-2fa/process-2fa.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: 'forgot', component: ForgetPasswordComponent},
  { path: 'two-step-verification', component: TwoStepVerificationComponent},
  { path: 'process-2fa', component: Process2faComponent},
  { path: 'verify-email', component: VerifyEmailComponent},
  { path: 'reset-password', component: ResetPasswordComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
