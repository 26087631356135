import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { first, map } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../../../core/models/user';
import { UsersService } from 'src/app/core/services/users.service';


@Component({
  selector: 'app-process-2fa-verification',
  templateUrl: './process-2fa.component.html',
  styleUrls: ['./process-2fa.component.css']
})
export class Process2faComponent implements OnInit {

  imageData: string | null = null; // Initialize as null or with appropriate data type
  state$: Observable<object>;
  verificationCode: string = '';
  errorMessage = "";
  roleValue = -1;
  user: User;
  emailVerification = false;
  selectedMethod: string;
  twoFactorEnabled = false;
  secretKey = "";
  twoFaModal: boolean = true;
  qrModal: boolean = false;
  emailModal: boolean = false;
  successModal: boolean = false;

  constructor(private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private usersService: UsersService,
  ) {
  }

  ngOnInit(): void {
  this.authService.tempUser.subscribe(user => {
    this.user = user;
  });

    }

  onCheckboxChange(event: any, method: string) {
    if (event.target.checked) {
      this.selectedMethod = method;
    } else {
      this.selectedMethod = '';
    }
  }

  onContinueClick() {
    if (this.selectedMethod == "app") {
      this.ngxService.startLoader("loader-01");
      this.secretKey = this.user.secretKey;
      if (!this.secretKey) {
        this.usersService.getUser(this.user.id).subscribe(
          (result) => {
            this.secretKey = result.secretKey;
          },
          (error) => {
            console.error('Error fetching user:', error);
          }
        );
      }
      this.authService.getQRCodeImage(this.secretKey,this.user.email).subscribe(
        (imageData) => {
          this.imageData = imageData["data"]
          this.ngxService.stopLoader("loader-01");
        },
        (error) => {
          console.error("Error fetching two-factor image:", error);
          this.ngxService.stopLoader("loader-01");
        }
      );
      this.twoFaModal  = false;
      this.emailModal = false;
      this.qrModal = true;

    }
    else {
      this.ngxService.startLoader("loader-01");
      this.authService.send2FaEmail(this.user.id).subscribe(
        (result) => {
          this.qrModal = false;
          this.twoFaModal = false;
          this.emailModal = true;
          this.ngxService.stopLoader("loader-01");
        },
        (error) => {
          console.error("Error sending email", error);
          this.toastr.error("Error in sending verification code to email, try again")
          const customSwitch1Checkbox = document.getElementById('customSwitch1') as HTMLInputElement;
          customSwitch1Checkbox.checked = false;
          this.twoFactorEnabled = false;
          this.twoFaModal = true;
          this.qrModal = false;
          this.emailModal = false;
          this.ngxService.stopLoader("loader-01");
        }
      );



    }

  }

  verifyQRCode() {
    this.ngxService.startLoader("loader-01");
    this.authService.verifyQRCode(this.secretKey, this.verificationCode, this.user.id).subscribe(
      (isValid) => {
        if (isValid["data"]) {
          // Code is valid
          this.errorMessage = '';
          this.ngxService.stopLoader("loader-01");
          localStorage.setItem('isValidated', 'true');
          var validatedUsers = JSON.parse(localStorage.getItem('validatedUsers')) || [];
          validatedUsers.push(this.user.id);
          var updatedValidatedUsers = JSON.stringify(validatedUsers);
          localStorage.setItem('validatedUsers', updatedValidatedUsers);
          this.user.isValidated = true;
          localStorage.setItem('currentUser', JSON.stringify(this.user));
          this.authService.setCurrentUser();
          this.qrModal = false;
          this.emailModal = false;
          this.twoFaModal = false;
          this.successModal = true;
        } else {
          // Code is expired
          this.errorMessage = "The code is incorrect - check your app and try again";
          this.ngxService.stopLoader("loader-01");
        }
      },
      (error) => {
        console.error("Error fetching two-factor image:", error);
        // Handle error appropriately
        this.ngxService.stopLoader("loader-01");
      }
    );

  }

  verifyEmailCode() {
    this.ngxService.startLoader("loader-01");
    this.authService.verifyEmailCode(this.verificationCode, this.user.id).subscribe(
      (isValid) => {
        if (isValid["data"]) {
          // Code is valid
          this.errorMessage = '';
          this.ngxService.stopLoader("loader-01");
          localStorage.setItem('isValidated', 'true');
          var validatedUsers = JSON.parse(localStorage.getItem('validatedUsers')) || [];
          validatedUsers.push(this.user.id);
          var updatedValidatedUsers = JSON.stringify(validatedUsers);
          localStorage.setItem('validatedUsers', updatedValidatedUsers);
          this.user.isValidated = true;
          localStorage.setItem('currentUser', JSON.stringify(this.user));
          this.authService.setCurrentUser();
          this.qrModal = false;
          this.emailModal = false;
          this.twoFaModal = false;
          this.successModal = true;
        } else {
          this.errorMessage = "The code is incorrect or expired - try again";
          this.ngxService.stopLoader("loader-01");
        }
      },
      (error) => {
        console.error("Error fetching two-factor image:", error);
        this.ngxService.stopLoader("loader-01");
      }
    );

  }




  signin() {
    this.ngxService.startLoader("loader-01");
    if (this.user.twoFaType == "app") {
      this.authService.verifyQRCode(this.user.secretKey, this.verificationCode, this.user.id).subscribe(
        (isValid) => {
          if (isValid["data"]) {
            this.handleCodeVerification();
          } else {
            // Code is expired
            this.errorMessage = "The code is incorrect - check your app and try again";
            this.ngxService.stopLoader("loader-01");
          }
        },
        (error) => {
          console.error("Error fetching two-factor image:", error);
          // Handle error appropriately
          this.ngxService.stopLoader("loader-01");
        }
      );

    }
    else {
      this.authService.verifyEmailCode(this.verificationCode, this.user.id).subscribe(
        (isValid) => {
          if (isValid["data"]) {
           this.handleCodeVerification();
          } else {
            this.errorMessage = "The code is incorrect - check your email and try again";
            this.ngxService.stopLoader("loader-01");
          }
        },
        (error) => {
          console.error("Error fetching two-factor image:", error);
          this.ngxService.stopLoader("loader-01");
        }
      );
    }

  }
  send2FaEmail() {
    this.ngxService.startLoader("loader-01");
    this.authService.send2FaEmail(this.user.id).subscribe(
      (result) => {
        this.ngxService.stopLoader("loader-01");
      },
      (error) => {
        console.error("Error sending email", error);
        this.ngxService.stopLoader("loader-01");
      }
    );
  }
handleCodeVerification() {
  this.ngxService.stopLoader("loader-01");
  this.roleValue = this.user.role;
  if (this.roleValue == 1 || this.roleValue == 2|| this.roleValue == 5|| this.roleValue == 6|| this.roleValue == 7 || this.roleValue == 9) {
    let redirectUrl = this.route.snapshot.queryParamMap.get('redirect');
    if(redirectUrl != null){
      var urlRegex = /^(?:https?:\/\/)?(?:www\.)?(?:localhost|\b(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,}\b)(?::\d{1,5})?(?:\/[\w.-]*)*(?:\?.*)?$/i;

      if (urlRegex.test(redirectUrl)) {
        var redirect = redirectUrl.split("://")[1].split("/").slice(1).join("/");
        if(redirect !== null && redirect !== undefined && redirect.trim() !== '') {
          this.router.navigateByUrl('/' + redirect);
          return;
      }
    }
  }

  if (this.roleValue == 6 )
    this.router.navigateByUrl("/deliverables");
    else
    this.router.navigateByUrl("/dashboard");

}
  // else if (this.roleValue == 6){
  //   let redirectUrl = this.route.snapshot.queryParamMap.get('redirect');
  //   this.router.navigateByUrl("/" + redirectUrl.slice(redirectUrl.indexOf('deliverables')));
  // }
  else if (this.roleValue == 8){
    this.router.navigateByUrl("/eua-inventory");
  }
  else {
    this.router.navigateByUrl("/deliverables");
  }

}


sendAgain() {
  this.ngxService.startLoader("loader-01");
  this.authService.send2FaEmail(this.user.id).subscribe(
    (result) => {
      this.toastr.success("Verification code sent successfully","success")
      this.ngxService.stopLoader("loader-01");
    },
    (error) => {
      console.error("Error sending email", error);
      this.ngxService.stopLoader("loader-01");
    }
  );
}
}


