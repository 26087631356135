import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css']
})
export class TooltipComponent implements AfterViewInit {
  @Input() text: string = ''
  @Input() position = { top: '0px', left: '0px' }
  @ViewChild('tooltipContent', { static: false }) tooltipContent!: ElementRef

  constructor() { }

  ngAfterViewInit() {
    this.showTooltip()
  }

  showTooltip() {
    if (this.tooltipContent) {
      this.tooltipContent.nativeElement.classList.add('show')
    }
  }

}
