import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { RoundPipe } from '../../pipes/round.pipe';
import { UnitPipe } from '../../pipes/unit.pipe';

@Component({
  selector: 'app-toggable-input',
  templateUrl: './toggable-input.component.html',
  styleUrls: ['./toggable-input.component.css'],
  providers: [RoundPipe, UnitPipe]
})
export class ToggableInputComponent implements AfterViewInit {
  @Input() minValue: number;
  @Input() maxValue: number;
  @Input() value: any
  @Input() roundPrecision: number;
  @Input() disableInput: boolean = false;
  @Input() displayInput: boolean = false;
  @Input() unit: string
  @Output() valueChanged = new EventEmitter<number>()

  public isEditing = false;
  public editableValue: number;

  @ViewChild('input', { static: true }) input: ElementRef<HTMLInputElement>

  constructor(private roundPipe: RoundPipe, private unitPipe: UnitPipe) { }

  ngAfterViewInit(): void {
    if (this.input) this.input.nativeElement.focus()
  }

  ngOnChanges(): void {
    if (this.displayInput && !this.disableInput) {
      this.isEditing = true
      this.setInputValue()
    }
  }

  toggleEdit(): void {
    if (!this.displayInput && !this.disableInput) {
      this.isEditing = !this.isEditing;
      if (this.isEditing && !this.disableInput) {
        this.setInputValue()
      }
    }
  }

  setInputValue(): void {
    this.value = this.value.toString().split(' ')[0]
    this.editableValue = (this.roundPrecision) ? Number(this.roundPipe.transform(Number(this.value), this.roundPrecision)) : this.value
    this.input.nativeElement.focus()
  }

  onBlurOrEnter(): void {
    this.isEditing = false;
    this.value = this.editableValue
    this.valueChanged.emit(Number(this.value))
  }
}
