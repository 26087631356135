import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';

const adminRoutes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'dashboard/:id',
    loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'customers',
    loadChildren: () => import('./views/customers/customers.module').then(m => m.CustomersModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'vessels',
    loadChildren: () => import('./views/vessel/vessel.module').then(m => m.VesselModule)
  },
  {
    path: 'deliverables',
    loadChildren: () => import('./views/deliverables/deliverables.module').then(m => m.DeliverablesModule)
  },
  {
    path: 'administration',
    loadChildren: () => import('./views/administration/administration.module').then(m => m.AdministrationModule)
  },
  {
    path: 'suppliers',
    loadChildren: () => import('./views/suppliers/suppliers.module').then(m => m.SupplierModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./views/reports/reports.module').then(m => m.ReprotsModule)
  },
  {
    path: 'weather-report',
    loadChildren: () => import('./views/weather-report/weather-report.module').then(m => m.WeatherReportModule)
  },
  {
    path: 'alerts',
    loadChildren: () => import('./views/alerts/alerts.module').then(m => m.AlertsModule)
  },
  {
    path: 'eua-inventory',
    loadChildren: () => import('./views/eua-inventory/eua-inventory.module').then(m => m.EuaInventoryModule)
  },
  {
    path: 'bunker-planning',
    loadChildren: () => import('./views/bunker-planning/bunker-planning.module').then(m => m.BunkerPlanningModule)
  },
  {
    path: 'voyage-overview',
    loadChildren: () => import('./views/voyage-overview/voyage-overview.module').then(m => m.VoyageOverviewModule)
  },
  {
    path: 'fuel-strategy',
    loadChildren: () => import('./views/voyage-planner/voyage-planner.module').then(m => m.VoyagePlannerModule)
  }
];

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'error/404'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./views/authentication/auth.module').then(m => m.AuthModule)
      },
    ]
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGaurd],
    children: adminRoutes
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
