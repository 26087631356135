import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { SharedRoutingModule } from './shared-routing.module'
import { StarComponent } from './views/star/star.component'
import { ToggleButtonComponent } from './views/toggle-button/toggle-button.component'
import { ToggleAlertButtonComponent } from './views/toggle-alert-button/toggle-alert-button.component'
import { DateNormalisedPipe } from './pipes/date-normalized.pipe'
import { NoValuePipe } from './pipes/no-value.pipe'
import { RoundPipe } from '././pipes/round.pipe'
import { KPIComponent } from './views/kpi/kpi.component'
import { BaseProgressBarComponent } from './views/base-progress-bar/progress-bar.component'
import { BaseItemViewerComponent } from './views/base-item-viewer/base-item-viewer.component'
import { BaseComponent } from './views/base/base.component'
import { TooltipDirective } from './directives/tooltip.directive'
import { TooltipComponent } from './views/tooltip/tooltip.component'
import { DurationFormatPipe } from './pipes/duration.pipe'
import { RangeSliderComponent } from './views/range-slider/range-slider.component'
import { FormsModule } from '@angular/forms'
import { BaseMapComponent } from './views/base-map/base-map.component'
import { EuaCostOfEmissionPipe } from './pipes/emission-eua-cost'
import { UnitPipe } from './pipes/unit.pipe'
import { ToggableInputDirective } from './directives/toggable-input.directive'
import { MinMaxValidatorDirective } from './directives/min-max-validator.directive'
import { ToggableInputComponent } from './views/toggable-input/toggable-input.component'
import { HoverMenuDirective } from './directives/hover-menu.directive'
import { RouterModule } from '@angular/router'
import { OrderByPipe } from './pipes/order-by.pipe'
import { BreadcrumbComponent } from '../views/breadcrumb/breadcrumb.component'
import { LoadingViewComponent } from './views/loading-view/loading-view.component'
import { UploadButtonComponent } from './views/upload-button/upload-button.component'
import { CommentComponent } from './views/comment/comment.component'

@NgModule({
  declarations: [
    StarComponent,
    ToggleButtonComponent,
    ToggleAlertButtonComponent,
    DateNormalisedPipe,
    NoValuePipe,
    KPIComponent,
    BaseProgressBarComponent,
    BaseComponent,
    RoundPipe,
    BaseItemViewerComponent,
    TooltipDirective,
    TooltipComponent,
    RoundPipe,
    DurationFormatPipe,
    BaseMapComponent,
    RangeSliderComponent,
    EuaCostOfEmissionPipe,
    UnitPipe,
    ToggableInputDirective,
    ToggableInputComponent,
    MinMaxValidatorDirective,
    HoverMenuDirective,
    OrderByPipe,
    BreadcrumbComponent,
    LoadingViewComponent,
    UploadButtonComponent,
    CommentComponent,
  ],
  imports: [CommonModule, SharedRoutingModule, RouterModule, FormsModule],
  exports: [
    StarComponent,
    ToggleButtonComponent,
    ToggleAlertButtonComponent,
    DateNormalisedPipe,
    NoValuePipe,
    KPIComponent,
    BaseProgressBarComponent,
    BaseComponent,
    RoundPipe,
    BaseItemViewerComponent,
    TooltipDirective,
    TooltipComponent,
    RoundPipe,
    DurationFormatPipe,
    BaseMapComponent,
    EuaCostOfEmissionPipe,
    RangeSliderComponent,
    MinMaxValidatorDirective,
    UnitPipe,
    ToggableInputDirective,
    ToggableInputComponent,
    HoverMenuDirective,
    OrderByPipe,
    BreadcrumbComponent,
    LoadingViewComponent,
    UploadButtonComponent,
    CommentComponent,
  ],
})
export class SharedModule {}
