import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, tap } from 'rxjs'
import { environment } from 'src/environments/environment'
import { User } from '../models/user'

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  users = new BehaviorSubject<User[]>([])

  private _url: string = `${environment.apiUrl}/api/User/`

  constructor(private http: HttpClient) {}

  createUser(user: User) {
    return this.http.post(this._url, user)
  }

  getUsers(): Observable<User[]> {
    return this.http
      .get<User[]>(this._url)
      .pipe(tap((res: any) => this.users.next(res.data)))
  }

  getPostFixUsers(): Observable<User[]> {
    return this.http.get<User[]>(this._url + 'PostFixPIC')
  }

  getUser(id: string): Observable<User> {
    return this.http.get<User>(this._url + id)
  }

  getSurveyorUsersByPortId(portId: string): Observable<User> {
    return this.http.get<User>(this._url + 'SurveyorUsers/' + portId)
  }

  updateUser(user: User, userId: string) {
    return this.http.put(this._url + userId, user)
  }

  deleteUser(id: string) {
    return this.http.delete(this._url + id)
  }
}
