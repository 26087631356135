// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-card {
  width: 25rem
}

@media (max-width: 425px) {
 .login-card {
    margin: 5%;
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/views/authentication/login/login.component.css"],"names":[],"mappings":"AAAA;EACE;AACF;;AAEA;CACC;IACG,UAAU;IACV,WAAW;EACb;AACF","sourcesContent":[".login-card {\r\n  width: 25rem\r\n}\r\n\r\n@media (max-width: 425px) {\r\n .login-card {\r\n    margin: 5%;\r\n    width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
