import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateNormalised'
})
export class DateNormalisedPipe implements PipeTransform {
  transform(hours: number): string {
    let d = Math.floor(hours / 24)
    let h = Math.floor(hours % 24)
    let m = Math.floor((hours * 60) % 60)
    return `${d}d ${h}h ${m} min`
  }
}
