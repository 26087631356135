import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { vesselBunkerPlanningSchedules } from '../models/bunker-planning';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private _url: string = `${environment.apiUrl}/api/Dashboard/`;
  refresh$: Subject<any> = new Subject<any>()
  toggleVessels$: Subject<any> = new Subject<any>()
  toggleFilters$: Subject<any> = new Subject<any>()
  scrollToBottom$: Subject<any> = new Subject<any>()

  activeVesselSchedules: BehaviorSubject<vesselBunkerPlanningSchedules[]> = new BehaviorSubject<vesselBunkerPlanningSchedules[]>([])
  activeVesselSchedules$: Observable<vesselBunkerPlanningSchedules[]> = this.activeVesselSchedules.asObservable()

  constructor(private http: HttpClient) { }

  getRouteLinkToken(): Observable<any> {
    return this.http.get<any>(this._url + "RouteLinkMagicalToken")
  }

  refresh() {
    this.refresh$.next(true)
  }

  toggleVesselsList() {
    this.toggleVessels$.next(true)
  }

  toggleFilters() {
    this.toggleFilters$.next(true)
  }

  addSelectedActiveVesselSchedules(data: vesselBunkerPlanningSchedules[]) {
    this.activeVesselSchedules.next(data)
  }

}
