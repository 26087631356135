import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'euaCostOfEmission',
})
export class EuaCostOfEmissionPipe implements PipeTransform {

  transform(emission: number, euaPrice: number): number {
    return emission * euaPrice
  }

}
