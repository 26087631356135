import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { ActivatedRoute } from '@angular/router';
import { catchError, finalize, map, switchMap, switchMapTo, takeUntil } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, throwError } from 'rxjs';
import { NavigationService } from '../../services/navigation.service';

/** Use this component to display an item based on the id retrieved from router params */
@Component({
  template: '',
})
export class BaseItemViewerComponent extends BaseComponent implements OnInit {

  pageName: string

  constructor(
    protected route: ActivatedRoute,
    protected loaderService: NgxUiLoaderService,
  ) {
    super()
  }

  ngOnInit() {
  }

  /**
   * id param of activated route
   */
  get itemId(): string {
    return this.route.snapshot.paramMap.get('id')
  }

  /**
   * Loads entity based on activated route id
   * @param loadFunction {Observable<T>} - provide observable loading the item
   * @returns {T} entity type
   */
  protected loadItemDetails<T>(loadFunction: (id: string) => Observable<T>): Observable<T> {
    return this.route.params.pipe(
      switchMap(params => loadFunction(params.id)),
      catchError(error => throwError(() => new Error(error))),
      takeUntil(this.$destroyed),
      finalize(() => this.loaderService.stopLoader('loader-01'))
    )
  }

}
