import { Component, inject, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { NavigationService } from '../../services/navigation.service';

@Component({
  template: '',
})
export class BaseComponent implements OnDestroy {

  protected navivationService = inject(NavigationService)

  activePage: string
  $destroyed: Subject<any> = new Subject<any>()

  constructor(
  ) { }

  ngOnDestroy(): void {
    this.$destroyed.next(true)
    this.$destroyed.complete()
  }

  setActivePage(page: string) {
    this.navivationService.activatedPage.next(page || this.activePage)
  }

}
