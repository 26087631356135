export const environment = {
  production: true,
  downloadSurveyorFiles: "https://pordstoragec4bunkers.blob.core.windows.net/c4bunkers-attachments/SurveyorFiles.zip",
  etaCcEmail: "postfix@base-blue.com;global@base-blue.com",
  //routelink: "https://web-routelink-stage3.azurewebsites.net/",
  routelink: "https://web-routelink-prod-c4bunkers.azurewebsites.net/",
  apiUrl: "https://api-prod-c4bunkers.azurewebsites.net",
  //apiUrl: "https://api-bunkernet.azurewebsites.net"
  //apiUrl: "https://api-qa-bunkernet-stage3.azurewebsites.net",
  //apiUrl: "https://localhost:44319"
  firebaseConfig: {
    apiKey: "AIzaSyClCLATYwDrUkaQvcScNB2WpfmcaCWYiGA",
    authDomain: "g-map-e547b.firebaseapp.com",
    databaseURL: "https://g-map-e547b-default-rtdb.firebaseio.com",
    projectId: "g-map-e547b",
    storageBucket: "g-map-e547b.appspot.com",
    messagingSenderId: "1071734281860",
    appId: "1:1071734281860:web:ae6fb912df4e7babb994d1",
    measurementId: "G-T4DGJWPG14"
  }
};
