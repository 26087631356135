export class Utils {
  static isMobile() {
    return window && window.matchMedia('(max-width: 767px)').matches;
  }
  static ngbDateToDate(ngbDate: { month, day, year }) {
    if (!ngbDate) {
      return null;
    }
    return new Date(`${ngbDate.month}/${ngbDate.day}/${ngbDate.year} /$12:00:00`);
  }
  static ngbDateToDateStart(ngbDate: { month, day, year }) {
    if (!ngbDate) {
      return null;
    }
    return new Date(`${ngbDate.month}/${ngbDate.day}/${ngbDate.year} /$00:00:00`);
  }
  static ngbDateToString(ngbDate) {
    if (!ngbDate) {
      return null;
    }
    return new Date(`${ngbDate.month}/${ngbDate.day}/${ngbDate.year} /$00:00:00`);
  }
  static dateToNgbDate(date: Date) {
    if (!date) {
      return null;
    }
    date = new Date(date);
    return { month: date.getMonth() + 1, day: date.getDate(), year: date.getFullYear() };
  }
  static scrollToTop(selector: string) {
    if (document) {
      const element = <HTMLElement>document.querySelector(selector);
      element.scrollTop = 0;
    }
  }
  static genId() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
  static isValidNumber(value: any, minimumValue?: number) {
    if (value != null && String(value) && String(value).trim() != '' && Number(value) != null) {
      return minimumValue == null ? true : Number(value) >= minimumValue;
    }
    return false;
  }
  static starHtml(value?: number) {
    if (!value) { value = 0; }
    var htmlStr = "";
    [1, 2, 3, 4, 5].forEach(n => {
      htmlStr = htmlStr + '<i class="';
      htmlStr = htmlStr + (n <= value ? 'ti ti-star-filled' : (n - 0.7 <= value && n - 0.3 >= value) ? 'ti ti-star-half-filled' : 'ti ti-star');
      htmlStr = htmlStr + '"></i>';
    });
    return htmlStr;
  }
  static toDegreesMinutesAndSeconds(coordinate) {
    var absolute = Math.abs(coordinate);
    var degrees = Math.floor(absolute);
    var minutesNotTruncated = (absolute - degrees) * 60;
    var minutes = Math.floor(minutesNotTruncated);
    var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

    return (degrees) + "° " + (minutes) + "' " + (seconds) + '"';
  }
  static convertDMS(lat, lng) {
    var latitude = this.toDegreesMinutesAndSeconds(lat);
    var latitudeCardinal = lat >= 0 ? "N" : "S";

    var longitude = this.toDegreesMinutesAndSeconds(lng);
    var longitudeCardinal = lng >= 0 ? "E" : "W";

    return (
      latitude +
      " " +
      latitudeCardinal +
      " / " +
      longitude +
      " " +
      longitudeCardinal
    );
  }

  // Rhumbline Distances
  static modulo180(val) {
    if (Math.abs(val) > 180) {
      val = -(val % 180);
    }
    return val;
  };

  //custom function
  static inc_lat(lat) {
    var result = Math.log(Math.tan(this.toRad(45 + lat / 2)));
    return this.toDeg(result);
  }

  static toDeg(rad) {
    return (180 * rad) / Math.PI;
  }

  static toRad(deg) {
    return (Math.PI * deg) / 180;
  }

  static rhumbLineDistance(a, b, c, d) {
    // Differences latLon
    var dlat = c - a,
      dlon = this.modulo180(-d + b);

    var bearing = 0,
      distance = 0;

    // Computes the bearing
    // Increasing latitudes
    var inc_latA = this.inc_lat(a),
      inc_latB = this.inc_lat(c);
    // Special cases
    if (dlat === 0) {
      // East-West track
      bearing = 90;
    } else if (dlon === 0) {
      // North-South track
      bearing = 0;
    } else {
      // Default case
      bearing = this.toDeg(Math.atan(dlon / (inc_latA - inc_latB)));
    }

    // Applying correction depending of relative position of A vs. B
    if (dlon > 0 && bearing < 0) {
      // Case North West
      bearing += 360;
    } else {
      bearing += 180;
    }

    // Computes the distance
    // No horizontal track
    if (dlat !== 0) {
      distance = Math.abs(dlat / Math.cos(this.toRad(bearing)));
    } else {
      // Horizontal track
      distance = Math.abs(dlon * Math.cos(this.toRad(a)));
    }
    distance *= 60;

    // Return
    return distance;
  }

  static isEqualDate(date: Date, compareWith: Date): boolean {
    return date.getDate() === compareWith.getDate() &&
      date.getMonth() === compareWith.getMonth() &&
      date.getFullYear() === compareWith.getFullYear()
  }

  //custom RL distance
  static totalRLdistance(coords) {
    var totalLength = 0;
    for (let a = 1; a < coords.length; a++) {
      totalLength += this.rhumbLineDistance(coords[a - 1]["lat"], coords[a - 1]["lng"], coords[a]["lat"], coords[a]["lng"])
    }
    return totalLength
  }

  static getColor(index: number, reverse = false): string {
    const colors: string[] = [
      '#206A5D',
      '#9a95cc',
      '#427D9D',
      '#9BBEC8',
      '#DDF2FD',
      '#ECE3CE',
      '#363062',
      '#a8be94',
      '#64CCC5',
      '#4F709C',
    ]
    const reversed = [...colors].reverse()
    return reverse ? (reversed[index] || reversed[index - reversed.length]) : (colors[index] || colors[index - colors.length])
  }

  static round(number: number, precision: number = 2): number {
    return Number(number.toFixed(precision))
  }

  static isOdd(num: number): boolean {
    return num % 2 !== 0
  }
}
