import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noValue'
})
export class NoValuePipe implements PipeTransform {
  transform(value: number | string): string | number {
    if (value === undefined || value === null)
      return '-'
    else return value
  }
}
