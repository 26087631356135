import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { first, map } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../../../core/models/user';


@Component({
  selector: 'app-two-step-verification',
  templateUrl: './two-step-verification.component.html',
  styleUrls: ['./two-step-verification.component.css']
})
export class TwoStepVerificationComponent implements OnInit {

  imageData: string | null = null; // Initialize as null or with appropriate data type
  state$: Observable<object>;
  verificationCode: string = '';
  errorMessage = "";
  roleValue = -1;
  user: User;
  emailVerification = false;

  constructor(private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,) {
  }

  ngOnInit(): void {
  this.authService.tempUser.subscribe(user => {
    this.user = user;
    this.emailVerification = user.twoFaType === "email";
    if (this.emailVerification) {
      this.send2FaEmail();
    }
  });

    }

  signin() {
    this.ngxService.startLoader("loader-01");
    if (this.user.twoFaType == "app") {
      this.authService.verifyQRCode(this.user.secretKey, this.verificationCode, this.user.id).subscribe(
        (isValid) => {
          if (isValid["data"]) {
            this.handleCodeVerification();
          } else {
            // Code is expired
            this.errorMessage = "The code is incorrect - check your app and try again";
            this.ngxService.stopLoader("loader-01");
          }
        },
        (error) => {
          console.error("Error fetching two-factor image:", error);
          // Handle error appropriately
          this.ngxService.stopLoader("loader-01");
        }
      );

    }
    else {
      this.authService.verifyEmailCode(this.verificationCode, this.user.id).subscribe(
        (isValid) => {
          if (isValid["data"]) {
           this.handleCodeVerification();
          } else {
            this.errorMessage = "The code is incorrect - check your email and try again";
            this.ngxService.stopLoader("loader-01");
          }
        },
        (error) => {
          console.error("Error fetching two-factor image:", error);
          this.ngxService.stopLoader("loader-01");
        }
      );
    }

  }
  send2FaEmail() {
    this.ngxService.startLoader("loader-01");
    this.authService.send2FaEmail(this.user.id).subscribe(
      (result) => {
        this.ngxService.stopLoader("loader-01");
      },
      (error) => {
        console.error("Error sending email", error);
        this.ngxService.stopLoader("loader-01");
      }
    );
  }
handleCodeVerification() {
  this.ngxService.stopLoader("loader-01");
  localStorage.setItem('isValidated', 'true');
  var validatedUsers = JSON.parse(localStorage.getItem('validatedUsers')) || [];
  validatedUsers.push(this.user.id);
  var updatedValidatedUsers = JSON.stringify(validatedUsers);
  localStorage.setItem('validatedUsers', updatedValidatedUsers);
  localStorage.setItem('currentUser', JSON.stringify(this.user));
  localStorage.removeItem('tempUser');
  this.authService.setCurrentUser();
  this.roleValue = this.user.role;
  if (this.roleValue == 1 || this.roleValue == 2|| this.roleValue == 5|| this.roleValue == 6|| this.roleValue == 7 || this.roleValue == 9) {
    let redirectUrl = this.route.snapshot.queryParamMap.get('redirect');
    if(redirectUrl != null){
      var urlRegex = /^(?:https?:\/\/)?(?:www\.)?(?:localhost|\b(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,}\b)(?::\d{1,5})?(?:\/[\w.-]*)*(?:\?.*)?$/i;

      if (urlRegex.test(redirectUrl)) {
        var redirect = redirectUrl.split("://")[1].split("/").slice(1).join("/");
        if(redirect !== null && redirect !== undefined && redirect.trim() !== '') {
        this.router.navigateByUrl('/' + redirect);
        return;
      }
      }
    }
      if (this.roleValue == 6 )
        this.router.navigateByUrl("/deliverables");
        else
        this.router.navigateByUrl("/dashboard");
  }
  // else if (this.roleValue == 6){
  //   let redirectUrl = this.route.snapshot.queryParamMap.get('redirect');
  //   this.router.navigateByUrl("/" + redirectUrl.slice(redirectUrl.indexOf('deliverables')));
  // }
  else if (this.roleValue == 8){
    this.router.navigateByUrl("/eua-inventory");
  }
  else {
    this.router.navigateByUrl("/deliverables");
  }

}


sendAgain() {
  this.ngxService.startLoader("loader-01");
  this.authService.send2FaEmail(this.user.id).subscribe(
    (result) => {
      this.toastr.success("Verification code sent successfully","success")
      this.ngxService.stopLoader("loader-01");
    },
    (error) => {
      console.error("Error sending email", error);
      this.ngxService.stopLoader("loader-01");
    }
  );
}
}


